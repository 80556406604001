import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import myCustomFunction from "./scripts/customScript";
import Header from "./components/Header";
import Main from "./components/Main";
import VideoDetail from "./components/VideoDetail";
import Footer from "./components/Footer";
import "./css/reset.css";
import "./css/styles.css";
import config from './config';

import BASE_URL from './config';
// Replace this line
// const BASE_URL = config[process.env.NODE_ENV || 'development'].baseUrl;
// With the imported BASE_URL directly.



function App() {
  const [VideoPlayerComponent, setVideoPlayerComponent] = useState(null);
  const [videoPlayerName, setVideoPlayerName] = useState("");
  const [currentVideoId, setCurrentVideoId] = useState(35); // Default videoId
  const [videos, setVideos] = useState([]); // Keep all videos fetched
  const [filteredVideos, setFilteredVideos] = useState([]); // Store filtered videos

  useEffect(() => {
    myCustomFunction();

    // Determine which VideoPlayer is being used
    const playerName = process.env.REACT_APP_VIDEO_PLAYER || "VideoPlayer";
    setVideoPlayerName(playerName);

    // Dynamically import the VideoPlayer based on the environment variable
    (async () => {
      if (playerName === "VideoPlayer2") {
        const module = await import("./components/VideoPlayer2");
        setVideoPlayerComponent(() => module.default);
      } else {
        const module = await import("./components/VideoPlayer");
        setVideoPlayerComponent(() => module.default);
      }
    })();

    // Fetch all videos once at the start
    const fetchAllVideos = async () => {
      try {
        const response = await fetch(`${BASE_URL}/videos`);
        if (!response.ok) {
          throw new Error(`Failed to fetch videos: ${response.statusText}`);
        }
        const data = await response.json();
        setVideos(data);
        setFilteredVideos(data); // Initialize with all videos
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchAllVideos();
  }, []);

  const handleSearch = async (query) => {
    console.log("Searching for:", query); // Debugging log
    if (query) {
      try {
        const response = await fetch(`${BASE_URL}/tags/videos/${query}`);
        if (!response.ok) {
          throw new Error(`Failed to search videos: ${response.statusText}`);
        }
        const videos = await response.json();
        console.log("Filtered videos:", videos); // Debugging log
        setFilteredVideos(videos); // Update the filtered videos state with the data from the backend
      } catch (error) {
        console.error("Error searching videos:", error);
      }
    } else {
      setFilteredVideos(videos); // Reset to all videos if query is empty
    }
  };

  if (!VideoPlayerComponent) return <div>Loading Video Player...</div>;

  return (
    <Router>
      <div className="App">
        <Header onSearch={handleSearch} />
        <main>
          <div
            style={{
              padding: "1.5px",
              backgroundColor: "#f8f9fa",
              textAlign: "center",
            }}
          >
            <h3>Currently using: {videoPlayerName}</h3>
          </div>

          <div title="This is a test tooltip.">
            Hover over this text to see a tooltip
          </div>

          <Suspense fallback={<div>Loading Video Player...</div>}>
            <VideoPlayerComponent
              onVideoChange={setCurrentVideoId}
              videos={filteredVideos}
            />
          </Suspense>
          <Routes>
            <Route
              path="/"
              element={
                <Main currentVideoId={currentVideoId} onSearch={handleSearch} />
              }
            />
            <Route path="/video/:id" element={<VideoDetail />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
