import React from 'react';
import Search from './Search';

function Header({ onSearch }) {
  return (
    <header>
      <section className="header-card">
        <h2>Test</h2>
      </section>
      <nav>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="#about" aria-label="Navigate to About me section">About me</a></li>
          <li><a href="#work" aria-label="Navigate to Work section">Work</a></li>
          <li><a href="#contact" aria-label="Navigate to Contact Info section">Contact Info</a></li>
        </ul>
      </nav>
      <Search onSearch={onSearch} />
    </header>
  );
}

export default Header;
