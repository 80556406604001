import React from 'react';
import ReactDOM from 'react-dom/client'; // Ensure React 18+ compatibility
import './index.css'; // Import global styles
import App from './App'; // Main application component

// Entry point of the React app
const rootElement = document.getElementById('root');

// Ensure the root element exists before rendering
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement); // React 18+ createRoot API
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error('Root element not found. Unable to render the application.');
}

// Log environment for debugging (Optional)
if (process.env.NODE_ENV === 'development') {
  console.log('React app running in development mode.');
} else {
  console.log('React app running in production mode.');
}
