import BASE_URL from "../config";
import React, { useState, useEffect } from "react";


function Main({ currentVideoId, onSearch }) {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [showTagForm, setShowTagForm] = useState(false);
  const [newTagName, setNewTagName] = useState("");

  useEffect(() => {
    const fetchTags = async () => {
      try {
        console.log(`Fetching tags for video ID: ${currentVideoId}`);
        const response = await fetch(`${BASE_URL}/tags/video/${currentVideoId}`);
        if (!response.ok) throw new Error(`Failed to fetch tags: ${response.statusText}`);
        const data = await response.json();
        setTags(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
        setError(error.message);
      }
    };

    fetchTags();
  }, [currentVideoId]);

  const handleAddTag = async () => {
    try {
      const response = await fetch(`${BASE_URL}/tags/video/${currentVideoId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: newTagName }),
      });
      if (!response.ok) throw new Error(`Failed to create tag: ${response.statusText}`);
      const createdTag = await response.json();
      setTags((prevTags) => [...prevTags, createdTag]);
      setNewTagName("");
      setShowTagForm(false);
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  return (
    <main>
      <section className="video-tags">
        {error ? (
          <p style={{ color: "red" }}>Error: {error}</p>
        ) : tags.length > 0 ? (
          tags.map((tag) => <span key={tag.id}>{tag.name}</span>)
        ) : (
          <p>No tags available</p>
        )}
      </section>
      <button onClick={() => setShowTagForm(!showTagForm)}>Add Tag</button>
      {showTagForm && (
        <input
          value={newTagName}
          onChange={(e) => setNewTagName(e.target.value)}
          placeholder="Enter tag name"
        />
      )}
    </main>
  );
}

export default Main;
